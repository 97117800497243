var collapsed = true;
var collapse_block = false;
$('.menu-toggle').click(function(e){
	e.preventDefault();
	if(collapsed){
		uncollapse();
	} else {
		collapse();
	}
});
$('.nav a').click(function(e){
	if(!collapsed && !$(this).hasClass('no-collapse')){
		collapse();
	}
});
$('a[href^="#"]').click(function(e) {
	e.preventDefault();
	if(this.hash.length > 1)
		$('html, body').stop().animate({ 'scrollTop': $(this.hash).offset().top }, 600, 'swing');
});
$(window).resize(function(){
	if(collapsed && $(window).width() > 767){
		uncollapse();
	}
});
$(function(){
	// Height fix
	var heightFixBoxes = $('.js-height-fix');
	var heightFixMaxHeight = 0;
	var heightFixes = {};
	heightFixBoxes.each(function(){
	    var $this = $(this);
	    var height = $this.height();
	    var fixId = $this.data('height-fix-id');
	    // if element has no id, assign -1
	    if(typeof fixId === 'undefined'){
	        fixId = -1;
	        $this.data('height-fix-id', fixId);
	    }
	    if(typeof heightFixes[fixId] === 'undefined' || heightFixes[fixId] < height){
	        heightFixes[fixId] = height;
	    }
	}).each(function(){
	    $(this).css('minHeight', heightFixes[$(this).data('height-fix-id')]);
	});
});

function collapse () {
	$('.nav').css('display', 'none');
	$('.nav').css('opacity', 0);
	$('.nav li').css('height', 0);
	$('.menu-toggle > .fa').removeClass('fa-rotate-180');
	collapsed = true;
}

function uncollapse () {
	$('.nav').css('display', 'inline');
	$('.nav').css('opacity', 1);
	$('.nav li').css('height', 'auto');
	$('.menu-toggle > .fa').addClass('fa-rotate-180');
	collapsed = false;
}

$('.login-form-submit').click(function(e){
	$('.login-form').submit();
});

$('.toggle-login-form').click(function(e){
	if(!collapsed && $(window).width() <= 767)
		$('.login-form > *').animate({ height: 'toggle', 'padding-top': 'toggle', 'padding-bottom': 'toggle' });
	else
		$('.login-form > *').animate({ width: 'toggle', 'padding-left': 'toggle', 'padding-right': 'toggle' });
});

$(".submit-login").keyup(function(e){
	if(e.which === 13)
		$('.login-form').submit();
});

$('.button-confirm').click(function(e){
	var $confirm = $(this);
	if($confirm.data('is-confirm')){
		return;
	}
	e.preventDefault();

	$confirm
		.data('org-text', $confirm.html())
		.data('is-confirm', true)
		.addClass('button-prepend')
		.text('Bestätigen')
		.css('float', 'left');

	var $append;
	if($confirm.data('has-append')){
		$append = $confirm.siblings('.button').eq(0);
	} else {
		$append = $('<a href="#" style="float:left;" class="button button-append button-light button-hidden-right">Abbrechen</a>');
		$append.insertAfter($confirm);
		$confirm.data('has-append', true);
	}

	if($confirm.hasClass('button-small')){
		$append.addClass('button-small');
	}

	$append.click(function(e){
		e.preventDefault();

		$confirm
			.html($confirm.data('org-text'))
			.data('is-confirm', false)
			.removeClass('button-prepend');

		$append.addClass('button-hidden-right');
	});
	setTimeout(function(){
		$append.removeClass('button-hidden-right');
	}, 10);

});

function guid(delim){
	delim = (typeof delim != 'undefined') ? delim : '-';
	return ('xxxxxxxx' + delim + 'xxxx' + delim + '4xxx' + delim + 'yxxx' + delim + 'xxxxxxxxxxxx').replace(/[xy]/g, function(c) {
		var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
		return v.toString(16);
	});
}
